import type { UseFetchOptions } from "#app";
import { useUserStore } from "~/store/UserStore";

export const useFetchApi = (
  url: string,
  rootOptions?: UseFetchOptions<object>,
  isAuthRequire: boolean = true
) => {
  return useFetch(url, {
    ...rootOptions,
    retry: 2,

    async onRequest({ request, options }) {
      let authToken = "";
      if (useNuxtApp().ssrContext) {
        authToken = useCookie<string>("authToken", {}).value;
        // console.log("SSR context detected================= add auth token to headers", authToken);
      } else {
        await $fetch("/api/healthcheck", {
          headers: { "X-Requested-With": "XMLHttpRequest" },
          onResponse(context) {
            // console.log("Healthcheck response received");
            // console.log("headers", context.response.headers);
            authToken = context.response.headers.get("X-Auth-Token") || "";
            // console.log("authToken", authToken);
          },
        });
      }
      
      const isAuthenticated = useCookie('isAuthenticated').value;
      if (authToken) {
        // Ensure headers is an instance of Headers
        options.headers = {
          ...rootOptions?.headers, // Spread existing headers, if any
          ...options.headers, // Spread existing headers, if any
          // @ts-ignore
          Authorization: `Bearer  ${authToken}`, // Set the new header
        };
        // options.headers.append("Authorization", `Bearer  ${authToken}`);
      } else if (!authToken && isAuthRequire && isAuthenticated) {
        // console.info(`Request url${url} cancelled because authentication is required.`);
        
        await getNewTokenDebouncedFn();
        // debugger;
        // throw new Error(
        //   `Request url${url} cancelled because authentication is required.`
        // );
      }
    },
    // async onRequestError({ request, options, error }) {
    //     console.log("[fetch request error]");
    // },
    // async onResponse({ request, response, options }) {
    //     console.log("[fetch response]");
    // },
    async onResponseError({ request, response, options, error }) {
      //   console.log("[fetch response response]", response);
      //   console.log("[fetch response options]", options);
      const isAuthenticated = useCookie('isAuthenticated').value;
      if (response.status === 401 && isAuthenticated) {
        const isTokenAcquire = await getNewTokenDebouncedFn();
        if (isTokenAcquire) {
          useFetchApi(url, rootOptions);
        }
        // throw new Error("Request failed with status code 401");
      }
    },
  });
};

const getNewToken = async () => {
  try {
    const newAuth = await $fetch("/api/auth/refresh_token", {
      method: "POST",
    });
    console.log("New token", newAuth);
    return !!newAuth;
  } catch (error) {
    console.error("Error while getting new token", error?.statusCode);
    if (error?.statusCode === 401) {
      //logout
      useUnAutorize();
    }
    return null;
  }
};

const getNewTokenDebouncedFn = useDebounceFn(() => getNewToken(), 1000, {
   maxWait: 5000,
});
