import { useConstants } from "../constants";
import { getCategoryFilters } from "../api";
import { defineStore } from "pinia";
import { ref } from "vue";
import { getRedis, setRedis } from "../api/redis";
import { getParentCategoriesHttp, getCldCategoriesHttp } from "../api/category";
import { useURLs } from "~/constants/api.urls";
import { NODE_ENV } from "~/constants/env";
export const useCategoryStore = defineStore("category", () => {
  let categoryFilters = ref([]);
  const fullCategoryFilters = ref([]);
  const categoryLables = ref({});
  const loading = ref(false);
  const parentCategories = ref([]);
  const parentCategoriesObj = ref({});
  const parentCategoryLables = ref([]);
  const parentCategoriesWithPromo = ref([]);
  const cldCategories = ref([]);
  const fetchCategories = ({ prefix, filters, selectedLanguage }) => {
    loading.value = true;
    setTimeout(async () => {
      const response = await getCategoryFilters({
        prefix,
        filters,
        selectedLanguage,
      });
      categoryFilters.value = response.value;
      genCategoryLables();
    }, 0);
    // setTimeout(async () => {
    //   await genCategoryLables();
    // }, 0);

    loading.value = false;
  };
  const fetchAllCategories = async () => {
    // loading.value = true;
    getRedis("ALL_CATEGORIES").then((fromCache) => {
      fullCategoryFilters.value = fromCache;
    });

    const response = await getCategoryFilters({ prefix: "" });
    fullCategoryFilters.value = response.value;
    setRedis({ key: "ALL_CATEGORIES", value: response.value });

    genCategoryLables().then();
    return true;
    // loading.value = false;
  };
  const genCategoryLables = async () => {
    getRedis(useConstants["redis_keys"].CATEGORY_COMPUTED_LABELS).then(
      (fromCache) => {
        if (fromCache && Object.keys(fromCache).length) {
          // console.log("fromCache category", fromCache);
          categoryLables.value = fromCache;
        }
      }
    );

    // setTimeout(async () => {
    if (categoryFilters.value.length) {
      let languageDictionary = {};

      categoryFilters.value.forEach((categoryFilter) => {
        categoryFilter.parent_top_hits.hits.hits[0]._source.Values.category_tree[0].Data.forEach(
          (cat) => {
            let c1 = {};

            if (cat.Childrens.length > 0) {
              c1 = {
                [cat.Childrens[0].Code]: cat.Childrens[0].Labels,
              };

              if (cat.Childrens[0].Childrens.length > 0) {
                c1 = {
                  ...c1,
                  [cat.Childrens[0].Childrens[0].Code]:
                    cat.Childrens[0].Childrens[0].Labels,
                };
              }
            }

            categoryFilter.child_agg.buckets.forEach((bucket) => {
              bucket.parent_top_hits.hits.hits[0]._source.Values.category_tree[0].Data.forEach(
                (cat2) => {
                  if (cat2.Childrens.length > 0) {
                    c1 = {
                      ...c1,
                      [cat2.Childrens[0].Code]: cat2.Childrens[0].Labels,
                    };

                    if (cat2.Childrens[0].Childrens.length > 0) {
                      c1 = {
                        ...c1,
                        [cat2.Childrens[0].Childrens[0].Code]:
                          cat2.Childrens[0].Childrens[0].Labels,
                      };
                    }
                  }
                }
              );

              if (bucket.sub_child_agg && bucket.sub_child_agg.buckets) {
                bucket.sub_child_agg.buckets.forEach((subBucket) => {
                  subBucket.parent_top_hits.hits.hits[0]._source.Values.category_tree[0].Data.forEach(
                    (cat3) => {
                      if (cat3.Childrens.length > 0) {
                        c1 = {
                          ...c1,
                          [cat3.Childrens[0].Code]: cat3.Childrens[0].Labels,
                        };

                        if (cat3.Childrens[0].Childrens.length > 0) {
                          c1 = {
                            ...c1,
                            [cat3.Childrens[0].Childrens[0].Code]:
                              cat3.Childrens[0].Childrens[0].Labels,
                          };
                        }
                      }
                    }
                  );
                });
              }
            });

            languageDictionary = {
              ...languageDictionary,
              [cat.Code]: cat.Labels,
              ...c1,
            };
          }
        );
      });

      // console.log("assigning the CATEGORY_COMPUTED_LABELS ");
      categoryLables.value = {
        ...categoryLables.value,
        ...languageDictionary,
      };
      setRedis({
        key: useConstants["redis_keys"].CATEGORY_COMPUTED_LABELS,
        value: categoryLables.value,
      }).then();
      return categoryLables.value;
      // console.log("settled CATEGORY_COMPUTED_LABELS in cache");
    }
    // }, 1000);
  };
  const genParentCategoryLables = async () => {
    if (parentCategories.value.length) {
      let languageDictionary = {};

      parentCategories.value.forEach((categoryFilter) => {
        categoryFilter.parent_top_hits.hits.hits[0]._source.Values.category_tree[0].Data.forEach(
          (cat) => {
            let c1 = {};

            if (cat.Childrens.length > 0) {
              c1 = {
                [cat.Childrens[0].Code]: cat.Childrens[0].Labels,
              };

              if (cat.Childrens[0].Childrens.length > 0) {
                c1 = {
                  ...c1,
                  [cat.Childrens[0].Childrens[0].Code]:
                    cat.Childrens[0].Childrens[0].Labels,
                };
              }
            }

            languageDictionary = {
              ...languageDictionary,
              [cat.Code]: cat.Labels,
              ...c1,
            };
          }
        );
      });
      parentCategoryLables.value = languageDictionary;
      // console.log("parentCategoryLables.value", parentCategoryLables.value);
    }
  };

  const getParentCategories = async () => {
    const data = await getParentCategoriesHttp();
    // console.log("data", data);
    if (!data.value) {
      console.error("Error in fetching parent categories");
    }
    parentCategories.value = data.value;
    parentCategoriesObj.value = {
      ...Object.assign(
        {},
        ...parentCategories.value.map((x) => {
          return {
            [x.key]: { ...x },
          };
        })
      ),
    };
    await genParentCategoryLables();
  };
  const getCategoriesPromo = async () => {
    const url = useURLs[NODE_ENV].CATEGORY_PROMO_MENU;
    const { data } = await useFetch(url, {
      method: "GET",
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
      },
      retry: 1,
    });
    parentCategoriesWithPromo.value = data.value;
  };

  const getCldCategories = async () => {
    const cateData = await getCldCategoriesHttp();
    if (cateData.value && cateData.value?.length) {
      // filtering  out legacy 'Archives' category
      cldCategories.value = cateData.value.filter((x) => x.id !== "MU1TRLRMLV");
    }
  };
  return {
    categoryFilters,
    fullCategoryFilters,
    categoryLables,
    loading,
    fetchCategories,
    fetchAllCategories,
    getParentCategories,
    parentCategoryLables,
    parentCategories,
    parentCategoriesObj,
    genCategoryLables,
    getCategoriesPromo,
    parentCategoriesWithPromo,
    getCldCategories,
    cldCategories,
  };
});
