export const validateNSendContact = async ({
  token,
  email,
  message,
  phone,
  name,
}) => {
  const data = await useFetch("/api/validateTurnstileNSendContact", {
    key: token.substring(0, 10),
    method: "POST",
    body: JSON.stringify({ token, email, message, phone, name }),
    headers: {
      "Content-Type": "application/json",
    },
    retry: 1,
  });
  return data;
};
