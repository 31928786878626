import { CLD_ENV, NODE_ENV } from "../../constants/env";
import { useURLs } from "../../constants/api.urls";
import { useCldURLs } from "~/constants/apiv2.urls";

export const getProducts = async ({
  sortOrder,
  filterInput,
  range,
  startRowIndex,
  selectedFilters,
  selectedLanguage,
  // sessionData,
}) => {
  try {
    const url = useURLs[NODE_ENV].PRODUCT_SEARCH_URL;
    const payload = JSON.stringify({
      prefix: filterInput,
      range: range,
      startRowIndex: startRowIndex,
      filters: selectedFilters,
      selectedLanguage,
      sortOrder: sortOrder,
      // sessionData,
    });

    const { data } = await useFetchApi(
      url,
      {
        key: `${payload}`,
        method: "POST",
        body: payload,
        headers: {
          client_id: useURLs[NODE_ENV].CLIENT_ID,
          client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
          "Content-Type": "application/json",
        },
        retry: 1,
      },
      false
    );
    const productsCount = data.value["count"];
    const pageCount = Math.ceil(productsCount / range);
    const products = data.value["productsN"];

    const attributeFilters = data.value["aggregations"];

    return {
      success: true,
      products,
      attributeFilters: attributeFilters,
      productsCount,
      pageCount,
    };
  } catch (error) {
    console.error("error getting products", error);
    return { success: false };
  }
};

// eslint-disable-next-line no-unused-vars
export const getCategoryFilters = async ({ prefix, selectedLanguage }) => {
  const url = useURLs[NODE_ENV].CATEGORY_FILTERS_URL;
  const { data } = await useFetchApi(
    `${url}?prefix=${prefix}&selectedLanguage=${selectedLanguage}`,
    {
      key: prefix,
      method: "GET",
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
      },
      retry: 1,
    },
    false
  );
  return data;
};
export const getSuggestions = async ({ keyword, chainId }) => {
  try {
    if (!keyword) {
      return [];
    }

    const url = useURLs[NODE_ENV].SUGGESTION_URL;
    const { data } = await useFetch(`${url}${keyword}&chainId=${chainId}`, {
      key: `autocomplete-${keyword}`,
      method: "GET",
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
      },
      retry: 1,
    });

    // const { data } = await axios.get(`${url}${keyword}&chainId=${chainId}`, {
    //   headers: {
    //     client_id: useURLs[NODE_ENV].CLIENT_ID,
    //     client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
    //     "Content-Type": "application/json",

    //   },
    // });

    return data;
  } catch (error) {
    console.error("error getting suggestions", error);
    return [];
  }
};

export const findManyProductsByIds = async ({ ids = [], selectedLanguage }) => {
  if (!ids.length) {
    return;
  }

  const url = `${useURLs[NODE_ENV].PRODUCT_FIND_MANY_URL}?ids=${ids}`;
  const payload = JSON.stringify({
    ids: ids,
    selectedLanguage,
  });

  const { data } = await useFetchApi(
    url,
    {
      key: `${payload}`,
      method: "POST",
      body: payload,
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
      },
      retry: 1,
    },
    false
  );
  return data;
};

// TODO HANDLE
export const getProductPreSalePercentage = async (id) => {
  const isAuthenticated = useCookie("isAuthenticated").value;
  if (!isAuthenticated) {
    return false;
  }
  const url = `${useCldURLs[CLD_ENV].product.get_pre_sale_percentage}?stockId=${id}`;
  try {
    const { data } = await useFetchApi(url, {
      key: `get_product_pre_sale_percentage-${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      onResponseError({ response }) {
        if (response.status === 403) {
          useUnAutorize();
        }
      },

      retry: 1,
    },true);
    return data;
  } catch (error) {
    return -1;
  }
};

export const getReleaseProducts = async ({
  releaseFilters,
  startRowIndex,
  range,
  selectedLanguage,
  sortOrder,
}) => {
  try {
    const url = useURLs[NODE_ENV].PRODUCT_RELEASE_SEARCH_URL;
    const { data } = await useFetchApi(
      url,
      {
        key: JSON.stringify(releaseFilters),
        method: "POST",
        headers: {
          client_id: useURLs[NODE_ENV].CLIENT_ID,
          client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          releaseFilters,
          startRowIndex,
          range,
          selectedLanguage,
          sortOrder,
        }),

        retry: 1,
      },
      false
    );

    // const { data } = await axios.post(
    //   url,
    //   {
    //     releaseFilters,
    //     startRowIndex,
    //     range,
    //     selectedLanguage,
    //     sortOrder,
    //   },
    //   {
    //     headers: {
    //       client_id: useURLs[NODE_ENV].CLIENT_ID,
    //       client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer  ${localStorage.getItem("access_token")}`,
    //     },
    //   }
    // );
    const productsCount = data.value["count"];
    const pageCount = Math.ceil(productsCount / range);
    const products = data.value["productsN"];
    const attributeFilters = data.value["aggregations"];

    return {
      success: true,
      products,
      attributeFilters: attributeFilters,
      productsCount,
      pageCount,
    };
  } catch (error) {
    console.error("error getting products", error);
    return { success: false };
  }
};

export const getAllFutureReleaseProducts = async ({
  releaseFilters,
  startRowIndex,
  range,
  selectedLanguage,
  sortOrder,
}) => {
  try {
    const url = useURLs[NODE_ENV].PRODUCT_ALL_FUTURE_RELEASE_SEARCH_URL;
    const { data } = await useFetchApi(
      url,
      {
        key: JSON.stringify(releaseFilters),
        method: "POST",
        headers: {
          client_id: useURLs[NODE_ENV].CLIENT_ID,
          client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          releaseFilters,
          startRowIndex,
          range,
          selectedLanguage,
          sortOrder,
        }),

        retry: 1,
      },
      false
    );
    // const { data } = await axios.post(
    //   url,
    //   {
    //     releaseFilters,
    //     startRowIndex,
    //     range,
    //     selectedLanguage,
    //     sortOrder,
    //   },
    //   {
    //     headers: {
    //       client_id: useURLs[NODE_ENV].CLIENT_ID,
    //       client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer  ${localStorage.getItem("access_token")}`,
    //     },
    //   }
    // );
    const productsCount = data.value["count"];
    const pageCount = Math.ceil(productsCount / range);
    const products = data.value["productsN"];
    const attributeFilters = data.value["aggregations"];

    return {
      success: true,
      products,
      attributeFilters: attributeFilters,
      productsCount,
      pageCount,
    };
  } catch (error) {
    console.error("error getting products", error);
    return { success: false };
  }
};
/*
export const getProductsStocksByIds = async ({ ids }) => {
  const url = useURLs[NODE_ENV].PRODUCT_STOCK_BY_IDS_URL;

  const { data } = await useFetchApi(url, {
    key: id,
    method: "POST",
    headers: {
      client_id: useURLs[NODE_ENV].CLIENT_ID,
      client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ids,
    }),

    retry: 1,
  });

  // const { data } = await axios.post(
  //   url,
  //   {
  //     ids,
  //   },
  //   {
  //     headers: {
  //       client_id: useURLs[NODE_ENV].CLIENT_ID,
  //       client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer  ${localStorage.getItem("access_token")}`,
  //     },
  //   }
  // ); 
console.log("stock data", data);
return data;
};
*/

/*
export const findProduct = async ({ id, selectedLanguage }) => {
  if (!id) {
    return;
  }

  const url = `${useURLs[NODE_ENV].PRODUCT_FIND_URL}?id=${id}`;
  const payload = JSON.stringify({
    ids: id,
    selectedLanguage,
  });

  const { data } = await useFetchApi(url, {
    key: `${payload}`,
    method: "POST",
    body: payload,
    headers: {
      client_id: useURLs[NODE_ENV].CLIENT_ID,
      client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      "Content-Type": "application/json",

    },
    retry: 1,
  }, false);

  if (data.value && data.value["product"]) {
    return data.value["product"];
  } else {
    return null;
  }
}; */
