import { useCartStore } from "~/store/CartStore";
import { useProductStore } from "~/store/ProductStore";
import { useUserStore } from "~/store/UserStore";

// For using in pini stores and http call funcs
export const useUnAutorize = async () => {
  const userStore = useUserStore();
  const cartStore = useCartStore();
  const productStore = useProductStore();
  const route = useRoute();
  const router = useRouter();
  const localePath = useLocalePath();

  const { t } = useNuxtApp().$i18n;

  useNuxtApp().$showToast({ msg: t("session_expired"), type: "error" });
  // Logout http h3
  cartStore.resetCartStates();
  await userStore.logout();
  productStore.setProductsBackOrders({});
  userStore.setIsAuthenticated();
  router.push(localePath(`/account/login?redirectTo=${route.fullPath}`));
};
