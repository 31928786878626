import { defineStore } from "pinia";
import { ref } from "vue";
import { useCldURLs } from "~/constants/apiv2.urls";
import { useURLs } from "~~/constants/api.urls";
import { CLD_ENV } from "~~/constants/env";
import nuxtStorage from "nuxt-storage";
export const useOrderStore = defineStore("order", () => {
  const nuxtApp = useNuxtApp();
  const pending = ref(false);
  const orderHistory = ref([]);
  const countOrderHistory = ref(0);
  const totalDeliverables = ref(0);
  const supplierOrderIds = ref([]);
  const opendDocs = ref([]);
  const accountingSituation = ref({
    "capt": "",
    "c_ID": "",
    "isBlocked": null,
    "name": "",
    "bookedBalance": 0,
    "balance": 0,
    "credit": 0,
    "overLimit": 0,
    "lastPosBal": "",
    "maxDaysOver": 0,
    "action": "",
    "action2": "",
    "colorCreditAmount": "",
    "color": ""
});

  const downloadOrderHistory = async ({ startDate, endDate }) => {
    const url = `${useCldURLs[CLD_ENV].account.download_history}?startDate=${startDate}&endDate=${endDate}`;

    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {},
    });
    if (data.value?.error) {
      throw new Error(data.value?.error || "ERROR_DOWNLOAD");
    }
    const downloadUrl = window.URL.createObjectURL(data.value);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute(
      "download",
      `order_history_${startDate}_to_${endDate}.zip`
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  const getOrderHistory = async (url, body) => {
    const { data } = await useFetchApi(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return data.value;
  };

  const searchOrderHistory = async (params) => {
    const url = `${useCldURLs[CLD_ENV].account.search_order_history}${params}`;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return data.value;
  };
  const searchOrders = async (params) => {
    const url = `${useCldURLs[CLD_ENV].account.search_orders}${params}`;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return data.value;
  };
  const searchSupplierOrders = async () => {
    const url = `${useCldURLs[CLD_ENV].account.search_supplier}`;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    supplierOrderIds.value = Object.entries(data.value)
      .filter((x) => x[1] > 0)
      .map((x) => x[0].trim());
  };

  const getAllBackOrders = async (params) => {
    const url = `${useCldURLs[CLD_ENV].account.back_orders}${params}`;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return data.value;
  };
  const getDeliverableOrders = async (params) => {
    totalDeliverables.value = 0;
    const url = `${useCldURLs[CLD_ENV].account.deliverable_orders}${params}`;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    totalDeliverables.value = data.value.totalDeliverables;
    return data.value.dxGrid;
  };

  const getDeliverableOrdersTotal = async (ctx) => {
    console.log("getDeliverableOrdersTotal ctx=", ctx);
    const isAuthenticated = useCookie("isAuthenticated").value;
    if (!isAuthenticated) {
      return false;
    }
    totalDeliverables.value = 0;
    const url = useCldURLs[CLD_ENV].account.deliverable_orders_total;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    totalDeliverables.value = data.value;
    return data.value;
  };

  const getUnDeliverableOrders = async (params) => {
    totalDeliverables.value = 0;
    const url = `${useCldURLs[CLD_ENV].account.undeliverable_orders}${params}`;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    totalDeliverables.value = data.value.totalDeliverables;
    return data.value.dxGrid;
  };
  const getOpendDocs = async (params) => {
    const url = `${useCldURLs[CLD_ENV].account.open_docs}${params}`;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    opendDocs.value = data.value?.data;
    return data.value;
  };
  const getAccountingSituation = async () => {
    const isAuthenticated = useCookie("isAuthenticated").value;
    if (!isAuthenticated) {
      return false;
    }
    const url = useCldURLs[CLD_ENV].account.accounting_situation;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    accountingSituation.value = data.value;
  };
  const getAccountingSituationLocalStorage = async () => {
    
    const data = nuxtStorage.localStorage.getData("accountingSituation");
    if (data) {
      accountingSituation.value = JSON.parse(data);
      if (accountingSituation.value?.c_ID) {
        return;
      }
    } 
     if(!!useCookie('isAuthenticated').value) {
      await getAccountingSituation();
      nuxtStorage.localStorage.setData("accountingSituation", JSON.stringify(accountingSituation.value), 10, "m");
    }
    
  };
  const reOpendOrder = async ({ docNumber, cartId }) => {
    const url = `${useCldURLs[CLD_ENV].order.re_open}?cartId=${cartId}&docNumber=${docNumber}`;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("data.value", data.value);
    return data.value;
  };
  const getShipmentLink = async ({ id, docType, docNumber }) => {
    const url = `${useCldURLs[CLD_ENV].account.shipment_link}?id=${id}&docType=${docType}&docNumber=${docNumber}`;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data.value;
  };

  const setAccountingSituation = () => {
    
    nuxtStorage.localStorage.removeItem("accountingSituation");
    accountingSituation.value = {
      "capt": "",
      "c_ID": "",
      "isBlocked": null,
      "name": "",
      "bookedBalance": 0,
      "balance": 0,
      "credit": 0,
      "overLimit": 0,
      "lastPosBal": "",
      "maxDaysOver": 0,
      "action": "",
      "action2": "",
      "colorCreditAmount": "",
      "color": ""
  }
  };
  return {
    getOrderHistory,
    downloadOrderHistory,
    getDeliverableOrders,
    getDeliverableOrdersTotal,
    getUnDeliverableOrders,
    getAccountingSituation,
    getAccountingSituationLocalStorage,
    setAccountingSituation,
    totalDeliverables,
    searchOrderHistory,
    searchOrders,
    getAllBackOrders,
    reOpendOrder,
    opendDocs,
    getOpendDocs,
    orderHistory,
    countOrderHistory,
    searchSupplierOrders,
    supplierOrderIds,
    accountingSituation,
    getShipmentLink,
  };
});
