import { CLD_ENV, NODE_ENV } from "../../constants/env";
import { useURLs } from "../../constants/api.urls";
import { useUnAutorize } from "~/composables/unAuthHandler";
import { useCldURLs } from "~/constants/apiv2.urls";

export const getUser = async () => {
  const isAuthenticated = useCookie("isAuthenticated").value;
  if (!isAuthenticated) {
    return false;
  }
  const url = useURLs[NODE_ENV].USER;
  const { data } = await useFetchApi(
    url,
    {
      key: `user`,
      method: "GET",
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
      },
      retry: 1,
    },
    true
  );
  return data;
};
export const saveUser = async (payload) => {
  const url = useURLs[NODE_ENV].USER;
  const { data } = await useFetchApi(url, {
    key: `${payload}`,
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      client_id: useURLs[NODE_ENV].CLIENT_ID,
      client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      "Content-Type": "application/json",
    },
    retry: 1,
  },true);
  return data;
};
export const sendContactEmail = async ({ email, message, phone, name }) => {
  const url = useURLs[CLD_ENV].CLD_SEND_CONTACT_EMAIL;
  const data = await useFetch(url, {
    key: email,
    method: "POST",
    body: JSON.stringify({ message, phone, name, email }),
    headers: {
      "Content-Type": "application/json",
    },
    retry: 1,
  });
  return data;
};
export const sendContactEmailServer = async ({
  email,
  message,
  phone,
  name,
}) => {
  const url =  useCldURLs[CLD_ENV].user.send_email;
  // const url = useURLs[CLD_ENV].CLD_SEND_CONTACT_EMAIL;
  const data = await $fetch(url, {
    key: email,
    method: "POST",
    body: JSON.stringify({ message, phone, name, email }),
    headers: {
      "Content-Type": "application/json",
    },
    retry: 1,
  });
  console.log("from server http call to email contact", data);
  return data;
};

export const updateUser = async (payload) => {
  const url = useURLs[NODE_ENV].USER;
  const { data } = await useFetchApi(url, {
    key: `${payload}`,
    method: "PATCH",
    body: payload,
    headers: {
      client_id: useURLs[NODE_ENV].CLIENT_ID,
      client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      "Content-Type": "application/json",
    },
    retry: 1,
  },true);
  return data;
};

export const getCldUser = async () => {
  try {
    const url = useCldURLs[CLD_ENV].user.profile;
    const { data } = await useFetchApi(
      url,
      {
        key: `CLD_USER`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        retry: 1,
      }, true);

    return data;
  } catch (error) {
    console.log("error", error);
  }
  return false;
};

export const updateUserEmails = async (payload) => {
  try {
    const url = useCldURLs[CLD_ENV].user.email;
    const { data } = await useFetchApi(
      url,
      {
        key: `CLD_UPDATE_EMAILS`,
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
        retry: 1,
      },
      true
    );

    return data;
  } catch (error) {
    console.log("error", error);
  }
  return false;
};

export const getIsPurchaseOrderRequired = async () => {
  try {
    const url = useCldURLs[CLD_ENV].order.is_po_req;
    const { data } = await useFetchApi(url, {
      key: `IS_PO_Required`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      retry: 1,
    },true);

    return data;
  } catch (error) {
    console.log("error getting PO", error);
  }
  return false;
};
