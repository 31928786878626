import { defineStore } from "pinia";
import { ref, computed } from "vue";
import nuxtStorage from "nuxt-storage";
import { useURLs } from "~~/constants/api.urls";
import { CLD_ENV } from "~~/constants/env";

import { useUnAutorize } from "~/composables/unAuthHandler";
import { useCldURLs } from "~/constants/apiv2.urls";
export const useCartStore = defineStore("cart", () => {
  const pending = ref(false);
  const cartItems = ref([]);
  const qtyCart = ref(0);
  const cartData = ref({
    items: [],
    amountExcludingVat: 0,
    amountIncludingVat: 0,
    vatRate: 0,
  });
  const priceSingleQty = ref([]);
  const sessionData = ref({});
  const orderPlaceResponse = ref({});
  const showCartBtn = ref(false);

  const addToCart = async ({ sku, qty }) => {
    let alreadyAdded;
    pending.value = true;
    
    if (cartData.value?.items && cartData.value.items.length) {
      alreadyAdded = cartData.value.items.find((x) => x.articleId === sku);
    }
    let added;
    if (!alreadyAdded) {
      added = await addToCartHttp({
        sku: sku,
        qty,
      });
      
      pending.value = false;
      if (added.value) {
        const exist = added.value.items.find((x) => x.articleId === sku);
        if (exist) {
          const sumQty = added.value.items
            .map((item) => +item.quantity)
            .reduce((prev, next) => prev + next);

          qtyCart.value = sumQty;
          cartData.value = added.value;
          EventBus.emit("refresh-cart");
          return { status: true, added: true };
        } else {
          return { status: false, added: false };
        }
      } else {
        return { status: false, added: false };
      }
    } else {
      pending.value = true;
      let qtySum = qty + alreadyAdded.quantity;
      await UpdateProductQty({ qty: qtySum, dlId: alreadyAdded.dlId });
      pending.value = false;
      EventBus.emit("refresh-cart");
      return { status: true, added: false };
    }
  };
  const getFromCart = async () => {
    const data = await getCartHttp();
    /*
      if (data && data.value && data.value.Data) {
        cartData.value = data.value.Data;
        if (data.value.Data.items.length) {
          priceSingleQty.value = data.value.Data.items.map(
            ({ Quantity, Total }) => parseFloat(Total / Quantity).toFixed(2)
          );
          const sumQty = data.value.Data.items
            .map((item) => +item.Quantity)
            .reduce((prev, next) => prev + next);
          qtyCart.value = sumQty;
        }
      }
  
    */

    if (data && data.value) {
      cartData.value = data.value;
      if (data.value.items.length) {
        priceSingleQty.value = data.value.items.map(({ quantity, total }) =>
          parseFloat(total / quantity).toFixed(2)
        );
        const sumQty = data.value.items
          .map((item) => +item.quantity)
          .reduce((prev, next) => prev + next);
        qtyCart.value = sumQty;
      }
    }
  };
  const UpdateProductQty = async ({ qty, dlId }) => {
    const data = await UpdateQtyInCartHttp({ qty, dlId });
    // console.log("updated qty in cart", data.data);

    cartData.value = data.value;
    const sumQty = data.value.items
      .map((item) => +item.quantity)
      .reduce((prev, next) => prev + next);
    qtyCart.value = sumQty;
  };
  const removeLineCart = async ({ dlId }) => {
    const data = await removeFromCartHttp({ dlId });

    cartData.value = data.value;
    const sumQty = data.value.items.length
      ? data.value.items
          .map((item) => +item.quantity)
          .reduce((prev, next) => prev + next)
      : 0;

    qtyCart.value = sumQty;
  };
  const placeOrder = async ({ reference }) => {
    const data = await placeOrderHttp({ reference });
    orderPlaceResponse.value = data.value;
    if (orderPlaceResponse.value.status) {
      cartData.value = { items: [] };
      qtyCart.value = 0;
    }
    console.log("data CL Order place", data);
  };

  const resetCartStates = async () => {
    cartData.value = { items: [] };
    qtyCart.value = 0;
  };

  const resetCart = async () => {
    const data = await resetUserCartHttp();

    if (data.value && data.value.status) {
      cartData.value = { items: [] };
      qtyCart.value = 0;
    }
  };

  const showAddCartBtn = (value) => {
    showCartBtn.value = value;
  };
  const clearCartResponse = () => {
    orderPlaceResponse.value = {};
  };
  //#region http functions
  const addToCartHttp = async ({ sku, qty }) => {
    const payload = {
      sku: sku,
      qty: +qty,
    };
    const url = useCldURLs[CLD_ENV].cart.add;
    const { data } = await useFetchApi(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
      onResponseError({ response }) {
        if (response.status === 403) {
          useUnAutorize();
        }
      },
    });

    return data;
  };

  const UpdateQtyInCartHttp = async ({ qty, dlId }) => {
    const url = `${useCldURLs[CLD_ENV].cart.update}?qty=${qty}&dlId=${dlId}`;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      onResponseError({ response }) {
        if (response.status === 403) {
          useUnAutorize();
        }
      },
    });
    return data;
  };

  const getCartHttp = async () => {
    const isAuthenticated = useCookie("isAuthenticated").value;
    if (!isAuthenticated) {
      return false;
    }
    const url = useCldURLs[CLD_ENV].cart.get;

    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  };

  const removeFromCartHttp = async ({ dlId }) => {
    const url = `${useCldURLs[CLD_ENV].cart.remove}?dlId=${dlId}`;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  };

  const resetUserCartHttp = async () => {
    const url = useCldURLs[CLD_ENV].cart.reset;

    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  };

  const placeOrderHttp = async ({ reference }) => {
    const url = useCldURLs[CLD_ENV].order.place;

    const { data } = await useFetchApi(`${url}?reference=${reference}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return data;
  };
  const syncCartQty = (qty) => {
    qtyCart.value = qty;
  };
  //#endregion
  return {
    cartItems,
    priceSingleQty,
    sessionData,
    cartData,
    showCartBtn,
    orderPlaceResponse,
    qtyCart,
    addToCart,
    showAddCartBtn,
    getFromCart,
    UpdateProductQty,
    removeLineCart,
    placeOrder,
    resetCart,
    resetCartStates,
    clearCartResponse,
    syncCartQty,
  };
});
